import { typography } from '@/components/typography/typography';
import Image from 'next/image';
import React from 'react';
import { FeatureItem } from './feature-item';
import { isBrowser } from 'react-device-detect';


export const Feature = () => {
  return (
    <section id="features" className="flex flex-col gap-17 items-center bg-blue-50 w-full py-16">
      {
        isBrowser ?
        <>
          <div className="flex flex-col gap-8 items-center">
            <h2 className={typography({ type: 'h2', className: 'max-w-[80rem]' })}>
              The Ideal Web & Social Media Monitoring Platform For
            </h2>
            <p
              className={typography({
                type: 'headingDescription',
                color: 'gray',
                className: 'max-w-[51.6rem] text-center',
              })}
            >
              No matter what you’re looking for, we can create a custom solution for your business
            </p>
          </div>
          <div className="grid grid-cols-[repeat(3,_36.8rem)] grid-rows-[repeat(2,_minmax(28.6rem,_1fr))] gap-6">
            <FeatureItem
              className="row-span-1 col-span-1"
              iconSrc="/icons/digital-data.svg"
              title="Digital Data Monitoring"
              description="Observe how your project grows in digital among general audiences. See your project’s reach and engagement"
            />
            <FeatureItem
              className="row-span-2 col-span-1 overflow-hidden"
              iconSrc="/icons/competitor-monitoring.svg"
              color="blue"
              title="Competitor Monitoring"
              description="Keep track of your competitor's digital progress to keep up with the competition."
            >
              <Image
                src={'/images/competitor-monitoring.png'}
                alt=""
                width={369}
                height={369}
                className="absolute left-0 w-full h-auto bottom-[-10%]"
              />
            </FeatureItem>
            <FeatureItem
              className="row-span-1 col-span-1"
              iconSrc="/icons/audience.svg"
              title="Audience"
              description="Identify the audience of your project better!"
            />
            <FeatureItem
              className="row-span-1 col-span-1"
              iconSrc="/icons/viral.svg"
              title="What’s Viral?"
              description="Never FOMO! We will update you with viral contents and their performance on Digital"
            />
            <FeatureItem
              className="row-span-1 col-span-1"
              iconSrc="/icons/trending-now.svg"
              title="Trending Now"
              description="Never miss an opportunity to hop on the latest trend with your business!"
            />
          </div>
        </>:
        <>
          <div className="flex flex-col gap-8 items-center px-4">
            <h2 className={typography({ type: 'h3', className: 'max-w-[80rem] text-center' })}>
              The Ideal Web & Social Media Monitoring Platform For
            </h2>
            <p
              className={typography({
                type: 'headingDescription',
                color: 'gray',
                className: 'max-w-[51.6rem] text-center',
              })}
            >
              No matter what you’re looking for, we can create a custom solution for your business
            </p>
          </div>
          <div className="grid grid-cols-1 gap-4 px-4">
            <FeatureItem
              className="row-span-1 col-span-1"
              iconSrc="/icons/digital-data.svg"
              title="Digital Data Monitoring"
              description="Observe how your project grows in digital among general audiences. See your project’s reach and engagement"
            />
            <FeatureItem
              className="row-span-2 col-span-1 overflow-hidden"
              iconSrc="/icons/competitor-monitoring.svg"
              color="blue"
              title="Competitor Monitoring"
              description="Keep track of your competitor's digital progress to keep up with the competition."
            >
              <div className='mt-[100%]'>
              </div>
              <Image
                src={'/images/competitor-monitoring.png'}
                alt=""
                width={369}
                height={369}
                className="left-0 w-full h-auto bottom-[-10%] absolute"
              />
            </FeatureItem>
            <FeatureItem
              className="row-span-1 col-span-1"
              iconSrc="/icons/audience.svg"
              title="Audience"
              description="Identify the audience of your project better!"
            />
            <FeatureItem
              className="row-span-1 col-span-1"
              iconSrc="/icons/viral.svg"
              title="What’s Viral?"
              description="Never FOMO! We will update you with viral contents and their performance on Digital"
            />
            <FeatureItem
              className="row-span-1 col-span-1"
              iconSrc="/icons/trending-now.svg"
              title="Trending Now"
              description="Never miss an opportunity to hop on the latest trend with your business!"
            />
          </div>
        </>
      }
      
    </section>
  );
};
