import { RequestError } from './error';

export async function fetcher<T>(
  input: RequestInfo,
  init?: RequestInit,
  options?: { parseJson?: boolean },
): Promise<T> {
  const res = await fetch(input, init);
  const { parseJson = true } = options ?? {};

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const data = await res.json();
    const info = data?.message ?? res.statusText ?? 'Unknown error';
    throw new RequestError<T>('An error occurred while fetching the data.', info, res.status, data?.data ?? data);
  }

  if (!parseJson) {
    return res.blob() as any;
  }
  return res.json();
}

export function isRequestError<T>(error: any): error is RequestError<T> {
  return error instanceof RequestError;
}
