import { hasValue } from '@/utils/has-value';
import { useCallback, useMemo, useState } from 'react';

export function useQueriesController<T extends { [key: string]: string | number | null | undefined }>(
  initialData?: Partial<T>,
) {
  const [queryParams, setQueryParams] = useState<Partial<T>>(initialData ?? {});

  const modifyOptions = useCallback(
    (options?: Partial<T>, reset?: boolean) => {
      if (reset) {
        setQueryParams(options ?? ({} as Partial<T>));
      } else {
        setQueryParams({ ...queryParams, ...options });
      }
    },
    [queryParams],
  );

  const queryParamsString = useMemo(() => {
    const searchParams = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
      if (hasValue(value)) {
        searchParams.append(key, value.toString());
      }
    });
    const searhParamsString = searchParams.toString();
    return searhParamsString ? '?' + searhParamsString : '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(queryParams)]);

  return {
    queryParams,
    queryParamsString,
    modifyOptions,
  };
}
