import { button, Button } from '@/components/Button/button';
import { typography } from '@/components/typography/typography';
import { ComboBox } from '@/components/input/autocomplete';
import { useGetKeywordSuggestions } from '@/services/data/hooks/useGetKeywordSuggestions';
import { cva } from 'class-variance-authority';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useRouter } from 'next/router';
import { DataServices_GetKeywordSuggestions_KeywordSuggestions } from '@/services/data/models';
import { SearchQueryParamsKey } from '../search/constants';
import { isBrowser } from 'react-device-detect';

const sectionStyle = cva('flex items-center justify-between w-full max-w-[115.2rem]', {
  variants: {
    type: {
      normal: '',
      reverse: 'flex-row-reverse',
    },
  },
  defaultVariants: {
    type: 'normal',
  },
});

export const Why = () => {
  const [keyword, setKeyword] = React.useState<DataServices_GetKeywordSuggestions_KeywordSuggestions | null>(null);
  const router = useRouter();
  const suggestionQuery = useGetKeywordSuggestions();
  const setSearchQuery = useDebouncedCallback((value: string) => {
    suggestionQuery.modifyOptions({ q: value });
  }, 500);

  const redirectToSearchDetail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!keyword) return;

    router.push({
      pathname: '/search/detail',
      query: {
        [SearchQueryParamsKey.KEYWORDS_NAME]: keyword.keyword,
        [SearchQueryParamsKey.KEYWORDS_UUID]: keyword.keyword_group_uuid,
      },
    });
  };

  return (
    <section className="flex flex-col gap-16 items-center bg-white w-full py-16" id="why-alphalitical">
      {
        isBrowser ?
        <>
        <div className="flex flex-col gap-8 items-center">
          <h2 className={typography({ type: 'h2', className: 'max-w-[60rem]' })}>Why You Should Choose Our Service</h2>
          <p
            className={typography({
              type: 'headingDescription',
              color: 'gray',
              className: 'max-w-[51.6rem] text-center',
            })}
          >
            The easier way to get the best strategy for your business through our machine learning technology.
          </p>
        </div>
        <div className={sectionStyle()}>
          <div className="flex flex-col gap-4 items-stretch text-start justify-center max-w-[49rem]">
            <span className="text-lg leading-6 text-green-500">Digital</span>
            <h3 className={typography({ type: 'h3' })}>Monitor your digital project with ease</h3>
            <p className={typography({ type: 'headingDescription', color: 'gray', className: 'text-start' })}>
              As easy as posting social media content with deep data-driven understanding.
            </p>
            <Link href="/contact-us" className={button({ variant: 'secondary', className: 'w-[13.3rem] px-[unset]' })}>
              Get Started
            </Link>
          </div>
          <Image src={'/images/why/monitor.svg'} alt="" width={518} height={400} />
        </div>
        <div
          className={sectionStyle({
            type: 'reverse',
          })}
        >
          <div className="flex flex-col gap-4 items-stretch text-start justify-center max-w-[49rem]">
            <span className="text-lg leading-6 text-[#FF9500]">Competitor</span>
            <h3 className={typography({ type: 'h3' })}>Competitor Performance</h3>
            <p className={typography({ type: 'headingDescription', color: 'gray', className: 'text-start' })}>
              Recognize your competitor’s strength by monitoring them on their digital journey.
            </p>
            <Link href="/contact-us" className={button({ variant: 'secondary', className: 'w-[13.3rem] px-[unset]' })}>
              Get Started
            </Link>
          </div>
          <Image src={'/images/why/competitor.svg'} alt="" width={518} height={400} />
        </div>
        <div className={sectionStyle()}>
          <div className="flex flex-col gap-4 items-stretch text-start justify-center max-w-[49rem]">
            <span className="text-lg leading-6 text-[#32ADE6]">Audience</span>
            <h3 className={typography({ type: 'h3' })}>Identify your audience</h3>
            <p className={typography({ type: 'headingDescription', color: 'gray', className: 'text-start' })}>
              Be the master of your campaign's conversation by understanding your market's potential.
            </p>
            <Link href="/contact-us" className={button({ variant: 'secondary', className: 'w-[13.3rem] px-[unset]' })}>
              Get Started
            </Link>
          </div>
          <Image src={'/images/why/audience.svg'} alt="" width={518} height={400} />
        </div>
        <div
          className={sectionStyle({
            type: 'reverse',
          })}
        >
          <div className="flex flex-col gap-4 items-stretch text-start justify-center max-w-[49rem]">
            <span className="text-lg leading-6 text-[#BF5AF2]">Trends</span>
            <h3 className={typography({ type: 'h3' })}>Trends for you!</h3>
            <p className={typography({ type: 'headingDescription', color: 'gray', className: 'text-start' })}>
              Never missing out what's hot in digital and inspired by it or just jump on the bandwagon!
            </p>
            <form onSubmit={redirectToSearchDetail} className="flex items-center gap-4">
              <ComboBox
                items={suggestionQuery.data || []}
                onInputValueChange={setSearchQuery}
                onChange={setKeyword}
                isLoading={suggestionQuery.isLoading}
                itemToString={(keyword) => keyword?.keyword || ''}
              />
              <Button className={'w-[13.3rem] px-[unset]'}>Search</Button>
            </form>
          </div>
          <Image src={'/images/why/trends.svg'} alt="" width={518} height={400} />
        </div>
        </>:
        <>
        <div className="flex flex-col gap-8 items-center px-4">
          <h3 className={typography({ type: 'h3', className: 'max-w-[60rem] text-center' })}>Why You Should Choose Our Service</h3>
          <p
            className={typography({
              type: 'headingDescription',
              color: 'gray',
              className: 'max-w-[51.6rem] text-center',
            })}
          >
            The easier way to get the best strategy for your business through our machine learning technology.
          </p>
        </div>
        <div className='px-4'>
          <div className='flex justify-center mb-4'>
            <Image src={'/images/why/monitor.svg'} alt="" width={518} height={400}
              className='w-80'
            />
          </div>
          <div className="flex flex-col gap-4 items-stretch text-start justify-center max-w-[49rem]">
            <span className="text-lg leading-6 text-green-500">Digital</span>
            <h3 className={typography({ type: 'h3' })}>Monitor your digital project with ease</h3>
            <p className={typography({ type: 'headingDescription', color: 'gray', className: 'text-start' })}>
              As easy as posting social media content with deep data-driven understanding.
            </p>
            <Link href="/contact-us" className={button({ variant: 'secondary', className: 'w-[13.3rem] px-[unset]' })}>
              Get Started
            </Link>
          </div>
          
        </div>
        <div
          className="flex flex-col gap-8 items-center px-4"
        >
          <div className='flex justify-center mb-4'>
            <Image src={'/images/why/competitor.svg'} alt="" width={518} height={400}
                className='w-80'
              />
          </div>
          <div className="flex flex-col gap-4 items-stretch text-start justify-center max-w-[49rem]">
            <span className="text-lg leading-6 text-[#FF9500]">Competitor</span>
            <h3 className={typography({ type: 'h3' })}>Competitor Performance</h3>
            <p className={typography({ type: 'headingDescription', color: 'gray', className: 'text-start' })}>
              Recognize your competitor’s strength by monitoring them on their digital journey.
            </p>
            <Link href="/contact-us" className={button({ variant: 'secondary', className: 'w-[13.3rem] px-[unset]' })}>
              Get Started
            </Link>
          </div>
        </div>
        <div className="flex flex-col gap-8 items-center px-4">
          <div className='flex justify-center mb-4'>
            <Image src={'/images/why/audience.svg'} alt="" width={518} height={400}
              className='w-80'
            />
          </div>
          <div className="flex flex-col gap-4 items-stretch text-start justify-center max-w-[49rem]">
            <span className="text-lg leading-6 text-[#32ADE6]">Audience</span>
            <h3 className={typography({ type: 'h3' })}>Identify your audience</h3>
            <p className={typography({ type: 'headingDescription', color: 'gray', className: 'text-start' })}>
              Be the master of your campaign's conversation by understanding your market's potential.
            </p>
            <Link href="/contact-us" className={button({ variant: 'secondary', className: 'w-[13.3rem] px-[unset]' })}>
              Get Started
            </Link>
          </div>
        </div>
        <div
          className="flex flex-col gap-8 items-center px-4"
        >
          <div className='flex justify-center mb-4'>
            <Image src={'/images/why/trends.svg'} alt="" width={518} height={400}
              className='w-80'
            />
          </div>
          <div className="flex flex-col gap-4 items-stretch text-start justify-center max-w-[49rem]">
            <span className="text-lg leading-6 text-[#BF5AF2]">Trends</span>
            <h3 className={typography({ type: 'h3' })}>Trends for you!</h3>
            <p className={typography({ type: 'headingDescription', color: 'gray', className: 'text-start' })}>
              Never missing out what's hot in digital and inspired by it or just jump on the bandwagon!
            </p>
            <form onSubmit={redirectToSearchDetail} className="grid grid-cols-1 items-center gap-4">
              <ComboBox
                items={suggestionQuery.data || []}
                onInputValueChange={setSearchQuery}
                onChange={setKeyword}
                isLoading={suggestionQuery.isLoading}
                itemToString={(keyword) => keyword?.keyword || ''}
                customClass='w-full'
              />
              <Button className={''}>Search</Button>
            </form>
          </div>
        </div>
        </>
      }
    </section>
  );
};
