import { useQueriesController } from '@/hooks/useQueriesController';
import { useQuery } from '@tanstack/react-query';
import { DataServices_GetKeywordSuggestions_Filter } from '../models';
import { DataServices, DataServicesKey } from '../service';

export function useGetKeywordSuggestions() {
  const queryController = useQueriesController<DataServices_GetKeywordSuggestions_Filter>();
  const { queryParamsString, queryParams, modifyOptions } = queryController;
  const data = useQuery({
    queryKey: [DataServicesKey.getKeywordSuggestions, queryParamsString],
    queryFn: () => DataServices.getKeywordSuggestions({ query: queryParamsString }),
    retry: 1,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  });
  return {
    ...data,
    queryParams,
    modifyOptions,
  };
}
