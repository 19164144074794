import { cva, cx, VariantProps } from 'class-variance-authority';
import Image from 'next/image';
import React, { FC } from 'react';
import styles from './feature-item.module.css';

export const featureItem = cva('rounded-2xl p-10 flex flex-col items-start gap-6 relative ', {
  variants: {
    color: {
      blue: 'bg-blue-700',
      white: 'bg-white',
    },
  },
  defaultVariants: {
    color: 'white',
  },
});

const featureItemHeading = cva('font-bold text-2xl leading-normal', {
  variants: {
    color: {
      blue: 'text-white',
      white: 'text-gray-850',
    },
  },
  defaultVariants: {
    color: 'white',
  },
});

const featureItemDescription = cva('font-normal text-base leading-snug', {
  variants: {
    color: {
      blue: 'text-white',
      white: 'text-gray-400',
    },
  },
  defaultVariants: {
    color: 'white',
  },
});

const featureItemIcon = cva(['flex justify-center', styles.iconWrapper], {
  variants: {
    color: {
      blue: 'bg-blue-800',
      white: 'bg-blue-700 bg-opacity-5',
    },
  },
  defaultVariants: {
    color: 'white',
  },
});

interface FeatureItemProps extends VariantProps<typeof featureItem> {
  children?: React.ReactNode;
  title: string;
  description: string;
  iconSrc?: string;
  className?: string;
}

export const FeatureItem: FC<FeatureItemProps> = ({ color, children, className, description, title, iconSrc }) => {
  return (
    <div className={featureItem({ color, className })}>
      {iconSrc ? (
        <div className={featureItemIcon({ color })}>
          <Image src={iconSrc} alt={title} height={40} width={40} />
        </div>
      ) : null}
      <h3
        className={featureItemHeading({
          color,
        })}
      >
        {title}
      </h3>
      <p className={featureItemDescription({ color })}>{description}</p>
      {children}
    </div>
  );
};
