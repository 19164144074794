import { cx } from 'class-variance-authority';
import { useCombobox } from 'downshift';

interface ComboBoxProps<T> {
  items: T[];
  selectedItem?: T | null;
  onChange?: (item: T | null) => void;
  itemToString?: (item: T | null) => string;
  itemRenderer?: (item: T) => React.ReactNode;
  getId?: (item: T) => string;
  inputValue?: string;
  onInputValueChange?: (value: string) => void;
  isLoading?: boolean;
  customClass?: string;
}

export function ComboBox<T>({
  items,
  selectedItem: selectedItemProp,
  onChange,
  itemToString = (item) => (typeof item === 'string' ? item : item ? JSON.stringify(item) : ''),
  itemRenderer,
  getId,
  inputValue,
  onInputValueChange,
  isLoading,
  customClass,
}: ComboBoxProps<T>) {
  const { isOpen, getMenuProps, getInputProps, highlightedIndex, getItemProps, selectedItem } = useCombobox({
    onInputValueChange({ inputValue }) {
      onInputValueChange?.(inputValue || '');
    },
    inputValue: inputValue,
    items,
    itemToString: itemToString,
    selectedItem: selectedItemProp,
    onSelectedItemChange({ selectedItem }) {
      onChange?.(selectedItem || null);
    },
  });
  let inputClass = "w-72 px-6 py-4 rounded-[1.4rem] border border-gray-400 text-base leading-6";
  if(customClass){
    inputClass = inputClass.concat(" ", customClass)
  }
  return (
    <div className="relative">
      <input
        placeholder="Search..."
        className={inputClass}
        {...getInputProps()}
      />
      <ul
        className={`absolute w-72 bg-white mt-1 shadow-md max-h-80 overflow-scroll p-0 z-10 ${
          !(isOpen && items.length) && 'hidden'
        }`}
        {...getMenuProps()}
      >
        {isOpen &&
          !isLoading &&
          items.map((item, index) => (
            <li
              className={cx(
                highlightedIndex === index && 'bg-blue-100',
                selectedItem === item && 'font-bold',
                'py-2 px-3 shadow-sm flex flex-col text-sm cursor-pointer',
              )}
              key={getId?.(item) ?? itemToString(item)}
              {...getItemProps({ item, index })}
            >
              {itemRenderer?.(item) ?? itemToString(item)}
            </li>
          ))}
      </ul>
    </div>
  );
}
