import { cva } from 'class-variance-authority';

export const typography = cva('', {
  variants: {
    type: {
      h2: 'font-medium text-5xl leading-normal w-full text-center ',
      h3: 'text-4xl font-bold leading-snug',
      headingDescription: 'font-normal text-lg leading-7 w-full',
    },
    color: {
      black: 'text-gray-900',
      gray: 'text-gray-400',
      white: 'text-white',
    },
  },
  defaultVariants: {
    type: 'h2',
    color: 'black',
  },
});
