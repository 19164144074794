import { button } from '@/components/Button/button';
import { cx } from 'class-variance-authority';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect } from 'react';
import styles from './hero.module.css';
import { SpinningText } from '@/components/spinning-text/spinning-text';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const PRODUCT_PROMISES = ['14 days free trial', 'No credit card required'];
const PRODUCT_FEATURES = ['Comparison', "What's Viral?", 'Digital Data Monitoring'];

export const Hero = () => {
  return (
    <section
      className={cx(
        'flex items-center justify-center w-full relative overflow-hidden bg-blue-50 pt-[10.4rem]',
        styles.container,
      )}
    >
      {
        isBrowser ?
        <>
          <div className="flex flex-col items-start  gap-10 max-w-2xl z-10">
            <span className="px-4 py-3 rounded-5xl bg-blue-700 bg-opacity-10 font-sans font-medium text-gray-500 text-base leading-6">
              Hello everyone 👋
            </span>
            <h1 className=" text-[5.6rem] font-extrabold text-gray-900 leading-12">
              Monitor your digital project with ease
              <br />
              <SpinningText className="text-blue-700" height="7.2rem" texts={PRODUCT_FEATURES} />
            </h1>
            <div className="flex flex-col gap-4 items-start">
              <div className="flex gap-4 ">
                {/* <Link href={process.env.NEXT_PUBLIC_LOGIN_URL || '#'} className={button({ variant: 'primary' })}>
                  Sign in with Google
                </Link> */}
                <Link href={'/contact-us'} className={button({ variant: 'primary' })}>
                  Request a Demo
                </Link>
              </div>
              <ul className="flex items-center justify-center gap-4 mt-4">
                {PRODUCT_PROMISES.map((promise) => (
                  <li className="flex gap-2 text-gray-500 text-base leading-6 items-center" key={promise}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.99992 15.1667C4.04659 15.1667 0.833252 11.9533 0.833252 8.00001C0.833252 4.04668 4.04659 0.833344 7.99992 0.833344C11.9533 0.833344 15.1666 4.04668 15.1666 8.00001C15.1666 11.9533 11.9533 15.1667 7.99992 15.1667ZM7.99992 1.83334C4.59992 1.83334 1.83325 4.60001 1.83325 8.00001C1.83325 11.4 4.59992 14.1667 7.99992 14.1667C11.3999 14.1667 14.1666 11.4 14.1666 8.00001C14.1666 4.60001 11.3999 1.83334 7.99992 1.83334Z"
                        fill="#FF9500"
                      />
                      <path
                        d="M7.05321 10.3867C6.91988 10.3867 6.79321 10.3333 6.69988 10.24L4.81321 8.35334C4.61988 8.16 4.61988 7.84 4.81321 7.64667C5.00655 7.45334 5.32655 7.45334 5.51988 7.64667L7.05321 9.18L10.4799 5.75334C10.6732 5.56 10.9932 5.56 11.1865 5.75334C11.3799 5.94667 11.3799 6.26667 11.1865 6.46L7.40655 10.24C7.31321 10.3333 7.18655 10.3867 7.05321 10.3867Z"
                        fill="#FF9500"
                      />
                    </svg>
                    {promise}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={cx('relative flex-shrink-0 z-10', styles.imageWrapper)}>
            <Image src={'/images/hero-landing-shadow.svg'} alt={''} width={388} height={108} className={styles.shadow} />
            <Image src={'/images/hero-alpha.png'} alt={'hero'} width={564} height={564} className={styles.image} />
          </div>
        </> :
        <>
        <div className='grid grid-cols-1 gap-4 px-4'>
          <div className='block'>
            <Image src={'/images/hero-landing-shadow.svg'} alt={''} 
              width={0}
              height={0}
              sizes="100vw"
              style={{ width: '100%', height: 'auto' }}
              className={styles.shadowMobile} 
              />
            <Image src={'/images/hero-alpha.png'} 
              alt={'hero'} width={100} height={100} 
              className={styles.imageMobile} 
            />
          </div>
          <div className="flex flex-col items-start mt-5 gap-8 max-w-2xl z-10">
            <span className="px-4 py-3 rounded-5xl bg-blue-700 bg-opacity-10 font-sans font-medium text-gray-500 text-base leading-6">
              Hello everyone 👋
            </span>
            <h1 className="text-[3.1rem] font-extrabold text-gray-900 leading-11">
              Monitor your digital project with ease
              <br />
              <SpinningText className="text-blue-700" height="4.2rem" texts={PRODUCT_FEATURES} />
            </h1>
            <div className="grid grid-cols-1 gap-4 w-full">
                {/* <Link href={process.env.NEXT_PUBLIC_LOGIN_URL || '#'} className={button({ variant: 'primary' })}>
                  Sign in with Google
                </Link> */}
                <Link href={'/contact-us'} className={button({ variant: 'primary' })}>
                  Request a Demo
                </Link>
              </div>
            <div className="flex justify-between w-full mb-6">
              {PRODUCT_PROMISES.map((promise) => (
                <div className="flex items-start w-fit gap-2 text-gray-500 text-base leading-6" key={promise}>
                  <svg className='mt-1' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.99992 15.1667C4.04659 15.1667 0.833252 11.9533 0.833252 8.00001C0.833252 4.04668 4.04659 0.833344 7.99992 0.833344C11.9533 0.833344 15.1666 4.04668 15.1666 8.00001C15.1666 11.9533 11.9533 15.1667 7.99992 15.1667ZM7.99992 1.83334C4.59992 1.83334 1.83325 4.60001 1.83325 8.00001C1.83325 11.4 4.59992 14.1667 7.99992 14.1667C11.3999 14.1667 14.1666 11.4 14.1666 8.00001C14.1666 4.60001 11.3999 1.83334 7.99992 1.83334Z"
                      fill="#FF9500"
                    />
                    <path
                      d="M7.05321 10.3867C6.91988 10.3867 6.79321 10.3333 6.69988 10.24L4.81321 8.35334C4.61988 8.16 4.61988 7.84 4.81321 7.64667C5.00655 7.45334 5.32655 7.45334 5.51988 7.64667L7.05321 9.18L10.4799 5.75334C10.6732 5.56 10.9932 5.56 11.1865 5.75334C11.3799 5.94667 11.3799 6.26667 11.1865 6.46L7.40655 10.24C7.31321 10.3333 7.18655 10.3867 7.05321 10.3867Z"
                      fill="#FF9500"
                    />
                  </svg>
                  {promise}
                </div>
              ))}
            </div>
          </div>
        </div>
        </>
      }
    </section>
  );
};
