export enum SearchStorageKey {
  KEYWORDS = '__ssk_k',
}

export enum SearchQueryParamsKey {
  KEYWORDS_UUID = '__sqpk_ku',
  KEYWORDS_NAME = '__sqpk_kn',
}

export const COLOR_INDICATOR = [
  '#32ADE6', // Blue
  '#9D216E', // Magenta
  '#F5A623', // Orange
  '#7ED321', // Green
  '#4A90E2', // Blue
  '#D0021B', // Red
  '#4A4A4A', // Gray
  '#FF5733', // Coral
  '#A832A8', // Purple
  '#61F542', // Lime
  '#E65F32', // Vermilion
  '#1B92D0', // Cyan
  '#F2A71D', // Amber
  '#FF42F9', // Pink
  '#3BFFC2', // Turquoise
  '#FFC233', // Yellow
  '#800000', // Maroon
  '#9A6324', // Brown
  '#808000', // Olive
  '#469990', // Teal
  '#000075', // Navy
  '#000000', // Black
  '#e6194b', // Red
  '#3cb44b', // Green
];
