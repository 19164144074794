import { cx } from 'class-variance-authority';
import React, { FC, useEffect, useMemo, useState } from 'react';
import styles from './spinning-text.module.css';

interface Props {
  texts: string[];
  height: number | string;
  className?: string;
}

export const SpinningText: FC<Props> = ({ texts, height, className }) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [animated, setAnimated] = useState(false);

  const renderedTexts = useMemo(() => {
    const currentText = texts[currentTextIndex];
    const prevText = texts[(currentTextIndex - 1 + texts.length) % texts.length];
    return [currentText, prevText];
  }, [currentTextIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((currentTextIndex + 1) % texts.length);
      setAnimated(true);
      setTimeout(() => {
        setAnimated(false);
      }, 1000);
    }, 3000);
    return () => clearInterval(interval);
  }, [currentTextIndex, texts.length]);

  return (
    <span className={cx('relative overflow-hidden inline-block w-full', className)} style={{ height }}>
      <span className="opacity-0 w-full">{texts[currentTextIndex]}</span>
      <div aria-hidden className={cx('absolute w-max flex flex-col top-0 left-0', animated && styles.slideDown)}>
        {renderedTexts.map((text, index) => (
          <span key={text} className="">
            {text}
          </span>
        ))}
      </div>
    </span>
  );
};
