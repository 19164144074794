export function hasValue(value: any): boolean {
  // handling null and undefined
  if (value == null) {
    return false;
  }

  if (Number.isNaN(value)) {
    return false;
  }

  if (typeof value === 'string' && value.trim() === '') {
    return false;
  }

  return true;
}
