import { cva, VariantProps } from 'class-variance-authority';
import React, { FC } from 'react';
import styles from './button.module.css';

export const button = cva(['px-10', 'py-4', 'text-base', 'leading-6', 'font-medium', 'flex', 'justify-center'], {
  variants: {
    variant: {
      primary: '',
      secondary: styles.secondaryBorder,
    },
    size: {
      sm: 'px-4 py-2 text-xs leading-5',
      md: 'px-6 py-4 text-xs leading-6 rounded-lg',
      lg: 'px-10 py-4 text-base leading-6 rounded-[1.4rem]',
    },
    tint: {
      blue: '',
      white: '',
    },
  },
  compoundVariants: [
    {
      variant: 'primary',
      tint: 'blue',
      className:
        'bg-blue-700 text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500',
    },
    {
      variant: 'secondary',
      tint: 'blue',
      className:
        'text-blue-700 border-blue-700 hover:bg-[rgba(255,255,255,0.25)] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500',
    },
    {
      variant: 'secondary',
      tint: 'white',
      className:
        'text-white border-white hover:bg-[rgba(255,255,255,0.25)] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white',
    },
  ],
  defaultVariants: {
    variant: 'primary',
    size: 'lg',
    tint: 'blue',
  },
});

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof button> {}

export const Button: FC<ButtonProps> = ({ className, variant, ...props }) => {
  return (
    <button
      {...props}
      className={button({
        className,
        variant,
      })}
    />
  );
};
