import { Hero } from '@/modules/landing-page/hero';
import { Customer } from '@/modules/landing-page/customer';
import { Feature } from '@/modules/landing-page/feature';
import { Why } from '@/modules/landing-page/why';
import { PlatformComparison } from '@/modules/landing-page/platform-comparison';
import { CommentSection } from '@/modules/landing-page/comment-section';
import { FAQSection } from '@/modules/landing-page/faq-section';
import { LiveDemoAnnouncement } from '@/modules/landing-page/live-demo-anouncement';
import { PricingSection } from '@/modules/landing-page/pricing';

const Main = () => {
  return (
    <div className="flex flex-col items-center min-h-screen w-full">
      {/* hero */}
      <Hero />
      {/* <Customer /> */}
      <Feature />
      <Why />
      <PlatformComparison />
      {/* <CommentSection /> */}
      {/* <PricingSection /> */}
      {/* <FAQSection /> */}
      <LiveDemoAnnouncement className="my-16" />
    </div>
  );
};

export default Main;
