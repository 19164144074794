import { typography } from '@/components/typography/typography';
import React from 'react';
import { isBrowser } from 'react-device-detect';

const OTHER_PLATFORMS_DISADVANTAGES = [
  'Many registration forms that require a business email',
  'Mandatory use of a credit card',
  'Messy and hard-to-understand graphs',
];
const ALPHALITICAL_ADVANTAGES = [
  'One-click trial',
  'No credit card required',
  'Easy data reading with Alpha Insight',
];

export const PlatformComparison = () => {
  return (
    <>
      {
      isBrowser ?
      <div className="flex flex-col gap-17 items-center bg-white w-full py-16">
        <div className="flex flex-col gap-8 items-center">
          <h2 className={typography({ type: 'h2', className: 'max-w-[82rem]' })}>
            Why Alphalitical better than other social listening platform
          </h2>
          <p
            className={typography({ type: 'headingDescription', color: 'gray', className: 'max-w-[60rem] text-center' })}
          >
            The easier way to get the best strategy for your business through our machine learning technology.
          </p>
        </div>
        <div className="grid grid-cols-2 w-full max-w-[115.2rem]">
          <div className="flex flex-col items-stretch">
            <h3 className="text-4xl leading-snug text-gray-900 mb-4 pr-6">Other Platform</h3>
            <p className="text-lg leading-7 text-gray-400 mb-8 pr-6">
              The easier way to get the best strategy for your business through our machine learning technology.
            </p>
            <ul>
              {OTHER_PLATFORMS_DISADVANTAGES.map((item) => (
                <li
                  key={item}
                  className="flex items-center gap-[2.2rem] px-6 py-4 text-gray-400 text-lg leading-snug even:bg-[#F6F7FB]"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className="w-6 h-6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
                      fill="#F82534"
                    />
                    <path
                      d="M9.17011 15.5799C8.98011 15.5799 8.79011 15.5099 8.64011 15.3599C8.35011 15.0699 8.35011 14.5899 8.64011 14.2999L14.3001 8.63986C14.5901 8.34986 15.0701 8.34986 15.3601 8.63986C15.6501 8.92986 15.6501 9.40986 15.3601 9.69986L9.70011 15.3599C9.56011 15.5099 9.36011 15.5799 9.17011 15.5799Z"
                      fill="#F82534"
                    />
                    <path
                      d="M14.8301 15.5799C14.6401 15.5799 14.4501 15.5099 14.3001 15.3599L8.64011 9.69986C8.35011 9.40986 8.35011 8.92986 8.64011 8.63986C8.93011 8.34986 9.41011 8.34986 9.70011 8.63986L15.3601 14.2999C15.6501 14.5899 15.6501 15.0699 15.3601 15.3599C15.2101 15.5099 15.0201 15.5799 14.8301 15.5799Z"
                      fill="#F82534"
                    />
                  </svg>

                  {item}
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col items-stretch">
            <h3 className="text-4xl leading-snug text-gray-900 mb-4">Alphalitical</h3>
            <p className="text-lg leading-7 text-gray-400 mb-8">
              The easier way to get the best strategy for your business through our machine learning technology.
            </p>
            <ul>
              {ALPHALITICAL_ADVANTAGES.map((item) => (
                <li
                  key={item}
                  className="flex items-center gap-[2.2rem] pr-6 py-4 text-gray-400 text-lg leading-snug even:bg-[#F6F7FB]"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className="w-6 h-6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
                      fill="#22C4AE"
                    />
                    <path
                      d="M10.5799 15.5801C10.3799 15.5801 10.1899 15.5001 10.0499 15.3601L7.21994 12.5301C6.92994 12.2401 6.92994 11.7601 7.21994 11.4701C7.50994 11.1801 7.98994 11.1801 8.27994 11.4701L10.5799 13.7701L15.7199 8.6301C16.0099 8.3401 16.4899 8.3401 16.7799 8.6301C17.0699 8.9201 17.0699 9.4001 16.7799 9.6901L11.1099 15.3601C10.9699 15.5001 10.7799 15.5801 10.5799 15.5801Z"
                      fill="#22C4AE"
                    />
                  </svg>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>:
      <div className="flex flex-col gap-17 items-center bg-white w-full py-16 px-4">
        <div className="flex flex-col gap-8 items-center">
          <h3 className={typography({ type: 'h3', className: 'text-center' })}>
            Why Alphalitical better than other social listening platform
          </h3>
          <p
            className={typography({ type: 'headingDescription', color: 'gray', className: 'max-w-[60rem] text-center' })}
          >
            The easier way to get the best strategy for your business through our machine learning technology.
          </p>
        </div>
        <div className="grid grid-cols-1 w-full gap-6">
          <div className="flex flex-col items-stretch">
            <h3 className="text-4xl leading-snug text-gray-900 mb-4 pr-6">Other Platform</h3>
            <p className="text-lg leading-7 text-gray-400 mb-8 pr-6">
              The easier way to get the best strategy for your business through our machine learning technology.
            </p>
            <ul>
              {OTHER_PLATFORMS_DISADVANTAGES.map((item) => (
                <li
                  key={item}
                  className="flex items-center gap-[2.2rem] px-6 py-4 text-gray-400 text-lg leading-snug even:bg-[#F6F7FB]"
                >
                  <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className="w-6 h-6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
                      fill="#F82534"
                    />
                    <path
                      d="M9.17011 15.5799C8.98011 15.5799 8.79011 15.5099 8.64011 15.3599C8.35011 15.0699 8.35011 14.5899 8.64011 14.2999L14.3001 8.63986C14.5901 8.34986 15.0701 8.34986 15.3601 8.63986C15.6501 8.92986 15.6501 9.40986 15.3601 9.69986L9.70011 15.3599C9.56011 15.5099 9.36011 15.5799 9.17011 15.5799Z"
                      fill="#F82534"
                    />
                    <path
                      d="M14.8301 15.5799C14.6401 15.5799 14.4501 15.5099 14.3001 15.3599L8.64011 9.69986C8.35011 9.40986 8.35011 8.92986 8.64011 8.63986C8.93011 8.34986 9.41011 8.34986 9.70011 8.63986L15.3601 14.2999C15.6501 14.5899 15.6501 15.0699 15.3601 15.3599C15.2101 15.5099 15.0201 15.5799 14.8301 15.5799Z"
                      fill="#F82534"
                    />
                  </svg>
                  </div>
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col items-stretch">
            <h3 className="text-4xl leading-snug text-gray-900 mb-4">Alphalitical</h3>
            <p className="text-lg leading-7 text-gray-400 mb-8">
              The easier way to get the best strategy for your business through our machine learning technology.
            </p>
            <ul>
              {ALPHALITICAL_ADVANTAGES.map((item) => (
                <li
                  key={item}
                  className="flex items-center gap-[2.2rem] px-6 py-4 text-gray-400 text-lg leading-snug even:bg-[#F6F7FB]"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className="w-6 h-6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
                      fill="#22C4AE"
                    />
                    <path
                      d="M10.5799 15.5801C10.3799 15.5801 10.1899 15.5001 10.0499 15.3601L7.21994 12.5301C6.92994 12.2401 6.92994 11.7601 7.21994 11.4701C7.50994 11.1801 7.98994 11.1801 8.27994 11.4701L10.5799 13.7701L15.7199 8.6301C16.0099 8.3401 16.4899 8.3401 16.7799 8.6301C17.0699 8.9201 17.0699 9.4001 16.7799 9.6901L11.1099 15.3601C10.9699 15.5001 10.7799 15.5801 10.5799 15.5801Z"
                      fill="#22C4AE"
                    />
                  </svg>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      }
    </>
  );
};
