export class RequestError<T> extends Error {
  info: string
  status: number
  data: T | null

  constructor(m: string, info: string, status: number, data: T | null = null) {
    super(m)
    this.info = info
    this.status = status
    this.data = data
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, RequestError.prototype)
  }
}
